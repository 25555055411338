/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import jwtDecode from 'jwt-decode';
import { PTTransparentIcon, SpinnerIcon, SpotifyIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import PTTextField from '../../components/TextField/TextField';
import { handleSpotifyExternalLogin, loginUser } from '../../services/auth';
import UserConsent from '../../components/UserConsent';

const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  captcha: Yup.string().required('Captcha verification is required'),
});

const Login = () => {
  const query = new URLSearchParams(window.location.search);
  const redirectTo = query.get('redirect_uri')
    ? query.get('redirect_uri')
    : global.config.HOME_PAGE;
  const navigate = useNavigate();
  const reCaptchaRef = useRef(null);

  window.localStorage.removeItem('playtreksNextStep');

  const handleOnLogin = async (values, formActions) => {
    Cookies.remove('jwtToken', {
      path: '/',
      domain: global.config.ssoAppDomain,
      expires: 1, // 1 day
      secure: true,
    });

    if (values.rememberMe) {
      window.localStorage.setItem('user', values.email);
    } else {
      window.localStorage.removeItem('user');
    }

    try {
      const response = await loginUser(values.email, values.password, values.captcha);

      if (response.status === 200) {
        if (!jwtDecode(response.data.token)?.verified) {
          window.localStorage.setItem('playtreksNextStep', redirectTo);

          navigate('/account/verify', { replace: true, state: { token: response.data.token } });
          return;
        }

        if (jwtDecode(response.data.token)?.twoFARequired) {
          window.localStorage.setItem('playtreksNextStep', redirectTo);

          navigate('/2fa/verify', { replace: true, state: { token: response.data.token } });
          return;
        }

        if (window?.opener) {
          window.opener.postMessage(response.data.token, process.env.REACT_APP_PLAYGROUNDS_APP_URL);
          window.close();
        }

        Cookies.set('jwtToken', response.data.token, {
          path: '/',
          domain: global.config.ssoAppDomain,
          expires: 1, // 1 day
          secure: true,
        });

        if (!redirectTo.includes('theplaygrounds.io')) {
          window.location.replace(decodeURIComponent(redirectTo));
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 429) {
        toast.error('Too many requests. Please try again later.');
      } else if (error.response?.data?.msg) {
        formActions.setStatus({ genericError: error.response.data.msg });
        reCaptchaRef?.current?.reset();
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleLogInWithSpotify = () => {
    window.localStorage.setItem('playtreksNextStep', redirectTo);

    handleSpotifyExternalLogin().catch(() =>
      toast.error('An unexpected error occurred. Please try again.')
    );
  };

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <div className="login-container absolute rounded-xl bg-gray-900 mt-32 top-0 sm:w-[459px]">
        <div className="px-3 sm:px-16 py-8">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="text-4xl font-inter-semibold">log in</div>
            <Link className="text-primary underline" to="/register" replace>
              Sign up
            </Link>
          </div>

          {/* Form */}
          <Formik
            validationSchema={loginFormValidationSchema}
            enableReinitialize
            initialValues={{
              email: window.localStorage.getItem('user') || '',
              password: '',
              rememberMe: false,
              captcha: '',
            }}
            initialStatus={{ genericError: '' }}
            onSubmit={handleOnLogin}
          >
            {(props) => {
              const {
                values: { email, password, rememberMe },
                errors,
                touched,
                status: { genericError },
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
              } = props;

              return (
                <Form>
                  <div className="mb-4">
                    <PTTextField
                      value={email}
                      label="Email"
                      name="email"
                      error={touched?.email && errors?.email}
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300 focus:border-0"
                    />
                  </div>

                  <div className="mb-4">
                    <PTTextField
                      value={password}
                      type="password"
                      label="Password"
                      name="password"
                      error={touched?.password && errors?.password}
                      placeholder="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="bg-gray-700 placeholder:text-gray-300"
                    />
                  </div>
                  <div className="mb-5">
                    <div className="flex justify-center">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey="6Ld4I70qAAAAAGG_2_QDdGcMgDgb7Ed0-Q3NQZ4I"
                        render="explicit"
                        onChange={(res) => {
                          if (res) {
                            setFieldValue('captcha', res);
                            setFieldTouched('captcha', true);
                          } else setFieldValue('captcha', '');
                        }}
                        onExpired={() => setFieldValue('captcha', '')}
                        onErrored={() => setFieldValue('captcha', '')}
                        theme="dark"
                        size={window.innerWidth < 768 ? 'compact' : 'normal'}
                      />
                    </div>
                    {touched.captcha && errors.captcha && (
                      <div className="mt-2 text-red-500 text-xs">{errors.captcha}</div>
                    )}
                  </div>

                  <PTButton
                    label="Log in"
                    primary
                    type="submit"
                    className="w-full h-14 mb-2 font-inter-semibold"
                    onClick={handleSubmit}
                    endIcon={isSubmitting ? <SpinnerIcon height={26} /> : null}
                  />

                  <div className="flex items-start justify-between mb-5">
                    <div
                      className="flex items-center cursor-pointer opacity-60 text-xs"
                      onClick={() => setFieldValue('rememberMe', !rememberMe)}
                    >
                      <div
                        onBlur={handleBlur}
                        className={`inline mt-1 w-4 h-4 border-2 border-primary rounded  ${
                          rememberMe ? 'bg-primary' : 'bg-gray-700'
                        }`}
                      />
                      &nbsp; <span className="ml-1">Remember me</span>
                    </div>

                    <div
                      className="text-primary underline cursor-pointer"
                      onClick={() => window.location.replace('/passwordreset')}
                    >
                      Forgot password?
                    </div>
                  </div>

                  {genericError && <div className="mb-4 text-red-500 text-sm">{genericError}</div>}
                </Form>
              );
            }}
          </Formik>
          <div>
            <PTButton
              startIcon={<img alt="Spotify" src={SpotifyIcon} className="loginSpotifyWhite" />}
              label="Log in with Spotify"
              className="w-full h-14 mb-3 hover:text-color-primary spotifyButton"
              onClick={handleLogInWithSpotify}
            />
          </div>

          <UserConsent />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
